import { Box, List, ListItem, ListItemText } from '@mui/material';
import { privacyContainerSx, privacySectionSx } from './Privacy.style';
import { Heading } from '../../components/Heading/Heading';
import { Copy } from '../../copy/Copy';
import { Text } from '../../components/Text/Text';

export const Privacy = () => {
  return (
    <Box component="div" sx={privacySectionSx}>
      <Box component="div" sx={privacyContainerSx}>
        <Heading
          level={1}
          text={Copy.generate('Privacy.title')}
          sx={{
            textAlign: 'center',
            fontSize: '32px !important',
            marginBottom: '16px',
          }}
        />

        <Text
          text={Copy.generate('Privacy.intro')}
          sx={{
            marginBottom: '16px',
          }}
        />

        <Box component="div">
          <Heading
            level={2}
            text={Copy.generate('Privacy.definitions.heading')}
            sx={{ fontSize: '20px !important' }}
          />

          <List sx={{ listStyle: 'decimal', pl: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={Copy.generate('Privacy.definitions.listItems.1')}
              />

              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Data</b>
                    </td>
                    <td>
                      collectively all information that you submit to{' '}
                      <span>M4MH Ltd</span> via the Website. This definition
                      incorporates, where applicable, the definitions provided
                      in the Data Protection Laws;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Cookies</b>
                    </td>
                    <td>
                      a small text file placed on your computer by this Website
                      when you visit certain parts of the Website and/or when
                      you use certain features of the Website. Details of the
                      cookies used by this Website are set out in the clause
                      below (<b>Cookies</b>);
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Data Protection Laws</b>
                    </td>
                    <td>
                      <p>
                        <span>
                          any applicable law relating to the processing of
                          personal Data, including but not limited to
                          the&nbsp;GDPR, and any national implementing and
                          supplementary laws, regulations and secondary
                          legislation;
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>GDPR</b>
                    </td>
                    <td>
                      <p>
                        <span>the UK General Data Protection Regulation;</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>
                        <span>M4MH Ltd</span>, <span>&nbsp;</span>
                        <br />
                        <span>&nbsp;</span>we{' '}
                      </b>{' '}
                      or <b>us</b>
                    </td>
                    <td>
                      <span>
                        {' '}
                        <span>M4MH Ltd</span>, a company incorporated in
                        <span>England and Wales</span> with registered number{' '}
                        <span>14858078</span> whose registered office is at{' '}
                        <span>17 Highfield Road</span>,&nbsp
                        <span>
                          <span>Hale</span>,{' '}
                        </span>
                        <span>
                          {' '}
                          <span>Altrincham</span>,{' '}
                        </span>{' '}
                        <span>WA15 8BX</span>;{' '}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>UK and EU Cookie Law</b>
                    </td>
                    <td>
                      the Privacy and Electronic Communications (EC Directive)
                      Regulations 2003 as amended by the Privacy and Electronic
                      Communications (EC Directive) (Amendment) Regulations 2011
                      &amp; the Privacy and Electronic Communications (EC
                      Directive) (Amendment) Regulations 2018;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>User</b> or <b>you</b>
                    </td>
                    <td>
                      any third party that accesses the Website and is not
                      either (i) employed by <span>M4MH Ltd</span> and acting in
                      the course of their employment or (ii) engaged as a
                      consultant or otherwise providing services to{' '}
                      <span>M4MH Ltd</span> and accessing the Website in
                      connection with the provision of such services; and
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Website</b>
                    </td>
                    <td>
                      the website that you are currently using,{' '}
                      <span>https://www.m4mh.app</span>, and any sub-domains of
                      this site unless expressly excluded by their own terms and
                      conditions.
                    </td>
                  </tr>
                </tbody>
              </table>
            </ListItem>

            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={Copy.generate('Privacy.definitions.listItems.1')}
              />
              <List sx={{ listStyle: 'decimal', pl: 4 }}>
                {Copy.generateArray(
                  'Privacy.definitions.diffInterpretationListItems',
                ).map((l, i) => (
                  <ListItem key={i} sx={{ display: 'list-item' }}>
                    <ListItemText primary={l} />
                  </ListItem>
                ))}
              </List>
            </ListItem>
          </List>
        </Box>

        <Box component="div">
          <Heading
            sx={{ fontSize: '20px !important' }}
            level={2}
            text={Copy.generate('Privacy.scope.heading')}
          />

          <List sx={{ listStyle: 'decimal', pl: 4 }}>
            {Copy.generateArray('Privacy.scope.listItems').map((l, i) => (
              <ListItem key={i} sx={{ display: 'list-item' }}>
                <ListItemText primary={l} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Box component="div">
          <Heading
            sx={{ fontSize: '20px !important' }}
            level={2}
            text={Copy.generate('Privacy.dataCollected.heading')}
          />

          <List sx={{ listStyle: 'decimal', pl: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={Copy.generate('Privacy.dataCollected.listItems.1')}
              />
              <List sx={{ listStyle: 'decimal', pl: 4 }}>
                {Copy.generateArray(
                  'Privacy.dataCollected.includesListItems',
                ).map((l, i) => (
                  <ListItem key={i} sx={{ display: 'list-item' }}>
                    <ListItemText primary={l} />
                  </ListItem>
                ))}
              </List>
            </ListItem>
          </List>
        </Box>

        <Box component="div">
          <Heading
            sx={{ fontSize: '20px !important' }}
            level={2}
            text={Copy.generate('Privacy.howCollectData.heading')}
          />

          <List sx={{ listStyle: 'decimal', pl: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={Copy.generate('Privacy.howCollectData.listItems.1')}
              />
              <List sx={{ listStyle: 'decimal', pl: 4 }}>
                {Copy.generateArray('Privacy.howCollectData.waysListItems').map(
                  (l, i) => (
                    <ListItem key={i} sx={{ display: 'list-item' }}>
                      <ListItemText primary={l} />
                    </ListItem>
                  ),
                )}
              </List>
            </ListItem>
          </List>
        </Box>

        <Box component="div">
          <Heading
            sx={{ fontSize: '20px !important' }}
            level={2}
            text={Copy.generate('Privacy.dataGiven.heading')}
          />

          <List sx={{ listStyle: 'decimal', pl: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={Copy.generate('Privacy.dataGiven.listItems.1')}
              />
              <List sx={{ listStyle: 'decimal', pl: 4 }}>
                {Copy.generateArray('Privacy.dataGiven.waysListItems').map(
                  (l, i) => (
                    <ListItem key={i} sx={{ display: 'list-item' }}>
                      <ListItemText primary={l} />
                    </ListItem>
                  ),
                )}
              </List>
            </ListItem>
          </List>
        </Box>

        <Box component="div">
          <Heading
            sx={{ fontSize: '20px !important' }}
            level={2}
            text={Copy.generate('Privacy.autoData.heading')}
          />

          <List sx={{ listStyle: 'decimal', pl: 4 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText
                primary={Copy.generate('Privacy.autoData.listItems.1')}
              />
              <List sx={{ listStyle: 'decimal', pl: 4 }}>
                {Copy.generateArray('Privacy.autoData.waysListItems').map(
                  (l, i) => (
                    <ListItem key={i} sx={{ display: 'list-item' }}>
                      <ListItemText primary={l} />
                    </ListItem>
                  ),
                )}
              </List>
            </ListItem>
          </List>
        </Box>

        <Box component="div">
          <Heading
            sx={{ fontSize: '20px !important' }}
            level={2}
            text={Copy.generate('Privacy.dataSecure.heading')}
          />

          <ol>
            <li>
              We will use technical and organisational measures to safeguard
              your Data, for example:
              <ol>
                <li>
                  access to your account is controlled by a password and a user
                  name that is unique to you.
                </li>
                <li>we store your Data on secure servers.</li>
              </ol>
            </li>
            <li>
              We are certified to ISO 27001. This family of standards helps us
              manage your Data and keep it secure.
            </li>

            <li>
              Technical and organisational measures include measures to deal
              with any suspected data breach. If you suspect any misuse or loss
              or unauthorised access to your Data, please let us know
              immediately by contacting us via this e-mail address:{' '}
              <span>crug.bx@gmail.com</span>.
            </li>
            <li>
              If you want detailed information from Get Safe Online on how to
              protect your information and your computers and devices against
              fraud, identity theft, viruses and many other online problems,
              please visit www.getsafeonline.org. Get Safe Online is supported
              by HM Government and leading businesses.
            </li>
          </ol>
        </Box>

        <Box component="div">
          <Heading
            sx={{ fontSize: '20px !important' }}
            level={2}
            text={Copy.generate('Privacy.dataRetention.heading')}
          />

          <List sx={{ listStyle: 'decimal', pl: 4 }}>
            {Copy.generateArray('Privacy.dataRetention.listItems').map(
              (l, i) => (
                <ListItem key={i} sx={{ display: 'list-item' }}>
                  <ListItemText primary={l} />
                </ListItem>
              ),
            )}
          </List>
        </Box>

        <Box component="div">
          <Heading
            sx={{ fontSize: '20px !important' }}
            level={2}
            text={Copy.generate('Privacy.yourRights.heading')}
          />

          <ol>
            <li>
              You have the following rights in relation to your Data:
              <ol>
                <li>
                  <b>Right to access</b> - the right to request (i) copies of
                  the information we hold about you at any time, or (ii) that we
                  modify, update or delete such information. If we provide you
                  with access to the information we hold about you, we will not
                  charge you for this, unless your request is{' '}
                  {`"manifestly unfounded or excessive."`}
                  Where we are legally permitted to do so, we may refuse your
                  request. If we refuse your request, we will tell you the
                  reasons why.
                </li>
                <li>
                  <b>Right to correct</b> - the right to have your Data
                  rectified if it is inaccurate or incomplete.
                </li>
                <li>
                  <b>Right to erase</b> - the right to request that we delete or
                  remove your Data from our systems.
                </li>
                <li>
                  <b>Right to restrict our use of your Data</b> - the right to
                  {`"block"`} us from using your Data or limit the way in which
                  we can use it.
                </li>
                <li>
                  <b>Right to data portability</b> - the right to request that
                  we move, copy or transfer your Data.
                </li>
                <li>
                  <b>Right to object</b> - the right to object to our use of
                  your Data including where we use it for our legitimate
                  interests.
                </li>
              </ol>
            </li>
            <li>
              To make enquiries, exercise any of your rights set out above, or
              withdraw your consent to the processing of your Data (where
              consent is our legal basis for processing your Data), please
              contact us via this e-mail address: <span>crug.bx@gmail.com</span>
              .
            </li>
            <li>
              If you are not satisfied with the way a complaint you make in
              relation to your Data is handled by us, you may be able to refer
              your complaint to the relevant data protection authority. For the
              UK, this is the Information {`Commissioner's `}Office (ICO). The{' '}
              {`ICO's`} contact details can be found on their website at
              https://ico.org.uk/.
            </li>
            <li>
              It is important that the Data we hold about you is accurate and
              current. Please keep us informed if your Data changes during the
              period for which we hold it.
            </li>
          </ol>
        </Box>

        <Box component="div">
          <Heading
            sx={{ fontSize: '20px !important' }}
            level={2}
            text={Copy.generate('Privacy.linksToOtherWebsites.heading')}
          />

          <List sx={{ listStyle: 'decimal', pl: 4 }}>
            {Copy.generateArray('Privacy.linksToOtherWebsites.listItems').map(
              (l, i) => (
                <ListItem key={i} sx={{ display: 'list-item' }}>
                  <ListItemText primary={l} />
                </ListItem>
              ),
            )}
          </List>
        </Box>

        <Box component="div">
          <Heading
            sx={{ fontSize: '20px !important' }}
            level={2}
            text={Copy.generate('Privacy.busOwnership.heading')}
          />

          <List sx={{ listStyle: 'decimal', pl: 4 }}>
            {Copy.generateArray('Privacy.busOwnership.listItems').map(
              (l, i) => (
                <ListItem key={i} sx={{ display: 'list-item' }}>
                  <ListItemText primary={l} />
                </ListItem>
              ),
            )}
          </List>
        </Box>

        <Box component="div">
          <Heading
            sx={{ fontSize: '20px !important' }}
            level={2}
            text={Copy.generate('Privacy.cookies.heading')}
          />

          <ol>
            <li>
              This Website may place and access certain Cookies on your
              computer.
              <span>
                {' '}
                <span>M4MH Ltd</span> uses Cookies to improve your experience of
                using the Website and to improve our range of services.{' '}
              </span>{' '}
              <span>M4MH Ltd</span> has carefully chosen these Cookies and has
              taken steps to ensure that your privacy is protected and respected
              at all times.
            </li>
            <li>
              All Cookies used by this Website are used in accordance with
              current UK and EU Cookie Law.
            </li>
            <li>
              Before the Website places Cookies on your computer, you will be
              presented with a message bar requesting your consent to set those
              Cookies. By giving your consent to the placing of Cookies, you are
              enabling <span>M4MH Ltd</span> to provide a better experience and
              service to you. You may, if you wish, deny consent to the placing
              of Cookies; however certain features of the Website may not
              function fully or as intended.
            </li>
            <li>
              This Website may place the following Cookies:
              <p>&nbsp;</p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Type of Cookie</b>
                    </td>
                    <td>
                      <b>Purpose</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Strictly necessary cookies</td>
                    <td>
                      These are cookies that are required for the operation of
                      our website. They include, for example, cookies that
                      enable you to log into secure areas of our website, use a
                      shopping cart or make use of e-billing services.
                    </td>
                  </tr>
                  <tr>
                    <td>Analytical/performance cookies</td>
                    <td>
                      They allow us to recognise and count the number of
                      visitors and to see how visitors move around our website
                      when they are using it. This helps us to improve the way
                      our website works, for example, by ensuring that users are
                      finding what they are looking for easily.
                    </td>
                  </tr>
                  <tr>
                    <td>Functionality cookies</td>
                    <td>
                      These are used to recognise you when you return to our
                      website. This enables us to personalise our content for
                      you, greet you by name and remember your preferences (for
                      example, your choice of language or region). By using the
                      Website, you agree to our placement of functionality
                      cookie.
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              You can find a list of Cookies that we use in the Cookies
              Schedule.
            </li>

            <li>
              You ca&nbsp;choose to enable or disable Cookies in your internet
              browser. By default, most internet browsers accept Cookies but
              this can be changed. For further details, please see the help menu
              in your internet browser. You can switch off &nbsp;Cookies at any
              time, however, you may lose any information that enables you to
              access the Website more quickly and efficiently.
            </li>
            <li>
              You can choose to delete Cookies at any time; however, you may
              lose any information that enables you to access the Website more
              quickly and efficiently including, but not limited to,
              personalisation settings.
            </li>
            <li>
              It is recommended that you ensure that your internet browser is
              up-to-date and that you consult the help and guidance provided by
              the developer of your internet browser if you are unsure about
              adjusting your privacy settings.
            </li>
            <li>
              For more information generally on cookies, including how to
              disable them, please refer to aboutcookies.org. You will also find
              details on how to delete cookies from your computer.
            </li>
          </ol>

          <p>
            Below is a list of the cookies that we use. We have tried to ensure
            this is complete and up to date, but if you think that we have
            missed a cookie or there is any discrepancy, please let us know.
          </p>

          <p>We use the following strictly necessary cookies:</p>

          <p>
            <strong>Analytical/Performance</strong>
          </p>

          <div>
            <p>We use the following analytical/performance cookies:</p>

            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Description</strong>
                  </td>
                  <td>
                    <strong>Purpose</strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Google Analytics</p>
                  </td>
                  <td>
                    <p>Website Optimisation</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Box>

        <Box component="div">
          <Heading
            sx={{ fontSize: '20px !important' }}
            level={2}
            text={Copy.generate('Privacy.general.heading')}
          />

          <List sx={{ listStyle: 'decimal', pl: 4 }}>
            {Copy.generateArray('Privacy.general.listItems').map((l, i) => (
              <ListItem key={i} sx={{ display: 'list-item' }}>
                <ListItemText primary={l} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Box component="div" sx={{ marginBottom: '16px' }}>
          <Heading
            sx={{ fontSize: '20px !important' }}
            level={2}
            text={Copy.generate('Privacy.changesToPolicy.heading')}
          />

          <List sx={{ listStyle: 'decimal', pl: 4 }}>
            {Copy.generateArray('Privacy.changesToPolicy.listItems').map(
              (l, i) => (
                <ListItem key={i} sx={{ display: 'list-item' }}>
                  <ListItemText primary={l} />
                </ListItem>
              ),
            )}
          </List>
        </Box>

        <Text text={Copy.generate('Privacy.created')} />
      </Box>
    </Box>
  );
};
