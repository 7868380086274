import { Box, useTheme } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { APP_PATHS } from '../../constants/paths';
import { teamMemberContainerSx } from './About.style';
import { TeamMember } from '../../components/TeamMember/TeamMember';
import { MissionList } from '../../components/MissionList/MissionList';
import { Hyperlink } from '../../components/Hyperlink/Hyperlink';

export const About = () => {
  const theme = useTheme();
  const tertiary = theme.palette.tertiary.main;
  const quaternary = theme.palette.quaternary.main;

  return (
    <>
      <ContentCard
        title={Copy.generate('About.journey.title')}
        heading={Copy.inject('About.journey.heading', {
          journey: dynamicTextColor('journey', quaternary),
        })}
        content={Copy.inject('About.journey.content', {
          decades: dynamicTextColor('decades of experience', tertiary),
          morale: dynamicTextColor('morale was low', quaternary),
          m4mh: dynamicTextColor('The M4MH wheels were in motion.', tertiary),
          empower: dynamicTextColor('empower people to talk', quaternary),
          more: dynamicTextColor(
            'But we had to do more, reach more.',
            tertiary,
          ),
          callUs: (
            <Hyperlink
              to={APP_PATHS.contact}
              text={Copy.generate('Links.call')}
              color="quaternary"
            />
          ),
        })}
        isColumn
        isTop
      />

      <ContentCard
        title={Copy.generate('About.mission.title')}
        heading={Copy.inject('About.mission.heading', {
          mission: dynamicTextColor('mission', quaternary),
        })}
        isColumn
        isSecondaryCard
      >
        <MissionList />
      </ContentCard>

      <ContentCard
        title={Copy.generate('About.who.title')}
        heading={Copy.inject('About.who.heading', {
          people: dynamicTextColor('people', quaternary),
        })}
        isColumn
      >
        <Box component="div" sx={teamMemberContainerSx}>
          {['crug', 'pete', 'nick'].map((t, i) => (
            <TeamMember
              key={i}
              name={Copy.generate(`About.who.${t}.name`)}
              img={Copy.generate(`About.who.${t}.img`)}
              bio={Copy.generate(`About.who.${t}.bio`)}
              href={Copy.generate(`About.who.${t}.href`)}
            />
          ))}
        </Box>
      </ContentCard>
    </>
  );
};
